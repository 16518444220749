import React, { Component } from 'react';
import { getUrlParams } from '../helpers'

class QuizForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {},
            fields: {
                quizScore: null,
                conditions: [],
                score: [],
            },
            errors: {
                quizNotFilled: 'Please answer all the questions',
                conditionsSelected: 'Please select a condition'
            },
            hasErrors: false,
        };
    }

    handleOptionChange = (event) => {
        const { name, value } = event.target;
        let answers = this.state.answers;
        answers[name] = value;
        this.setState({ answers, [name]: value });
        if (Object.values(answers).length === 10) {
            let answersError = this.state.errors
            answersError.quizNotFilled = ''
        }

    };

    handleCheckConditionElement = (event) => {
        let conditions = this.state.fields.conditions
        const ConditionSelected = conditions.indexOf(event.target.value)
        ConditionSelected ? this.setState({ conditions: conditions.push(event.target.value) }) : this.setState({ conditions: conditions.splice(ConditionSelected, 1) })
        if (conditions.length === 1 && !conditions.indexOf(event.target.value)) { conditions = [] }
        if (this.state.fields.conditions.length > 0) {
            let answersError = this.state.errors
            answersError.conditionsSelected = ''
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(this.state.errors)) {
            const answersArr = Object.values(this.state.answers);
            console.log(answersArr)
            let score = []
            answersArr.forEach((answer) => {
                switch (answer) {
                    case 'Never':
                        score.push('10')
                    break;
                    case 'Sometimes':
                        score.push('6')
                    break;
                    case 'Often':
                        score.push('4')
                    break;
                    case 'Very Often':
                        score.push('2')
                    break;
                    default:
                    break;
                }
            })
            const quizscore = score.reduce((a, b) => (parseInt(a) + parseInt(b)));
            this.setState({
                fields: {
                    quizScore: quizscore
                }
            })
            console.log(this.state)
            const storage = typeof window === 'undefined' ? null : window.sessionStorage;
            if (storage) {
                storage.setItem('score', `${quizscore}`)
                storage.setItem('conditions', `${this.state.fields.conditions}`)
                storage.setItem('q&a', JSON.stringify(this.state.answers))
            }
            window.location.pathname = '/quiz-results-lp'
        } else {
            this.setState({ hasErrors: true })
        }
    }


    render() {
        const dynamicHeader = (pageHeader) => {
            if (typeof window !== `undefined`) {
                const headline = getUrlParams(window.location.search).headline
                let header
                if (typeof headline !== `undefined`) {
                    let headerArr = headline.toString().split(" ")
                    let headerCap = []
                    headerArr.forEach((el) => headerCap.push(el.charAt(0).toUpperCase() + el.substring(1)))
                    header = headerCap.join(" ")
                } else {
                    header = pageHeader
                }
                return header
            }
        }
        const { hasErrors, errors, answers, } = this.state;
        return (
            <div className="jumbotron jumbotron-fluid with-shadow quiz-wrapper">
                <div className='d-flex flex-column align-items-center'>

                    <div>
                        <h1 className="is-purple font-weight-bold m-0 pb-4 text-center">{dynamicHeader(`Neurological Health Quiz`)}</h1>
                        <p className="is-purple font-weight-bold m-0 pb-4 text-center"> Suffering from depression, anxiety, PTSD, chronic pain or more? <br />Fill out the neurological quiz below and see your neurological health score.</p>
                        <form name="Quiz" method="post" onSubmit={this.handleSubmit} >
                            <div className="container">
                                <div className='border-bottom mt-2 align-items-center'>
                                    <div className="col-sm  pl-0 pr-0">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">Which condition or conditions describe you best?</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center flex-wrap">
                                            <label className="is-gray font-size-12" htmlFor="never">Depression</label>
                                            <div className="p-2"><input
                                                id='1'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='Depression'
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Anxiety</label>
                                            <div className="p-2"><input
                                                id='2'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='Anxiety'
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">PTSD</label>
                                            <div className="p-2"><input
                                                id='3'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='PTSD'
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Migraines</label>
                                            <div className="p-2"><input
                                                id='4'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='OCD'
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Chronic Pain</label>
                                            <div className="p-2"><input
                                                id='5'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='Chronic Pain'
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch"> Suicidality</label>
                                            <div className="p-2"><input
                                                id='6'
                                                type='checkbox'
                                                name='conditions'
                                                onChange={this.handleCheckConditionElement}
                                                value='Suicidality'
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-2 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I do things slowly</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_do_things_slowly'
                                                checked={answers.I_do_things_slowly === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_do_things_slowly'
                                                checked={answers.I_do_things_slowly === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_do_things_slowly'
                                                checked={answers.I_do_things_slowly === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_do_things_slowly'
                                                checked={answers.I_do_things_slowly === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">My future seems hopeless</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='My_future_seems_hopless'
                                                checked={answers.My_future_seems_hopless === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='My_future_seems_hopless'
                                                checked={answers.My_future_seems_hopless === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='My_future_seems_hopless'
                                                checked={answers.My_future_seems_hopless === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='My_future_seems_hopless'
                                                checked={answers.My_future_seems_hopless === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">It is hard for me to concentrate on reading</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='It_is_hard_for_me_to_concentrate_on_reading'
                                                checked={answers.It_is_hard_for_me_to_concentrate_on_reading === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='It_is_hard_for_me_to_concentrate_on_reading'
                                                checked={answers.It_is_hard_for_me_to_concentrate_on_reading === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='It_is_hard_for_me_to_concentrate_on_reading'
                                                checked={answers.It_is_hard_for_me_to_concentrate_on_reading === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='It_is_hard_for_me_to_concentrate_on_reading'
                                                checked={answers.It_is_hard_for_me_to_concentrate_on_reading === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I have difficulty making decisions</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_difficulty_making_decisions'
                                                checked={answers.I_have_difficulty_making_decisions === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_difficulty_making_decisions'
                                                checked={answers.I_have_difficulty_making_decisions === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_difficulty_making_decisions'
                                                checked={answers.I_have_difficulty_making_decisions === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_difficulty_making_decisions'
                                                checked={answers.I_have_difficulty_making_decisions === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I have lost interest in aspects of my life that used to be important to me</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me'
                                                checked={answers.I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me'
                                                checked={answers.I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me'
                                                checked={answers.I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me'
                                                checked={answers.I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I feel sad, blue, and unhappy</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_sad_blue_and_unhappy'
                                                checked={answers.I_feel_sad_blue_and_unhappy === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_sad_blue_and_unhappy'
                                                checked={answers.I_feel_sad_blue_and_unhappy === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_sad_blue_and_unhappy'
                                                checked={answers.I_feel_sad_blue_and_unhappy === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_sad_blue_and_unhappy'
                                                checked={answers.I_feel_sad_blue_and_unhappy === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I am agitated and keep moving around</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_am_agitated_and_keep_moving_around'
                                                checked={answers.I_am_agitated_and_keep_moving_around === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_am_agitated_and_keep_moving_around'
                                                checked={answers.I_am_agitated_and_keep_moving_around === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_am_agitated_and_keep_moving_around'
                                                checked={answers.I_am_agitated_and_keep_moving_around === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_am_agitated_and_keep_moving_around'
                                                checked={answers.I_am_agitated_and_keep_moving_around === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I feel fatigued</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_fatigued'
                                                checked={answers.I_feel_fatigued === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_fatigued'
                                                checked={answers.I_feel_fatigued === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_fatigued'
                                                checked={answers.I_feel_fatigued === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_feel_fatigued'
                                                checked={answers.I_feel_fatigued === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I sleep with interruptions, too much or too little</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_sleep_with_interruptions_too_much_or_too_little'
                                                checked={answers.I_sleep_with_interruptions_too_much_or_too_little === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_sleep_with_interruptions_too_much_or_too_little'
                                                checked={answers.I_sleep_with_interruptions_too_much_or_too_little === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_sleep_with_interruptions_too_much_or_too_little'
                                                checked={answers.I_sleep_with_interruptions_too_much_or_too_little === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_sleep_with_interruptions_too_much_or_too_little'
                                                checked={answers.I_sleep_with_interruptions_too_much_or_too_little === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row border-bottom mt-3 align-items-center'>
                                    <div className="col-xl-6 ">
                                        <p className="is-purple font-size-18 font-weight-bold mb-0">I don’t feel much joy even when good things happen to me</p>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-between is-purple align-items-center">
                                            <label className="is-gray font-size-12" htmlFor="never">Never</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_dont_feel_much_joy_even_when_good_things_happen_to_me'
                                                checked={answers.I_dont_feel_much_joy_even_when_good_things_happen_to_me === "Never"}
                                                onChange={this.handleOptionChange}
                                                value="Never"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="sometimes">Sometimes</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_dont_feel_much_joy_even_when_good_things_happen_to_me'
                                                checked={answers.I_dont_feel_much_joy_even_when_good_things_happen_to_me === "Sometimes"}
                                                onChange={this.handleOptionChange}
                                                value="Sometimes"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="often">Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_dont_feel_much_joy_even_when_good_things_happen_to_me'
                                                checked={answers.I_dont_feel_much_joy_even_when_good_things_happen_to_me === "Often"}
                                                onChange={this.handleOptionChange}
                                                value="Often"
                                            /></div>
                                            <label className="is-gray font-size-12" htmlFor="veryMuch">Very Often</label>
                                            <div className="p-2"><input
                                                type='radio'
                                                name='I_dont_feel_much_joy_even_when_good_things_happen_to_me'
                                                checked={answers.I_dont_feel_much_joy_even_when_good_things_happen_to_me === "Very Often"}
                                                onChange={this.handleOptionChange}
                                                value="Very Often"
                                            /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-1 mb-1 text-center">
                                    {errors.quizNotFilled.length > 0 && hasErrors &&
                                        <span className='error pt-1 pb-1'>{errors.quizNotFilled}</span>}
                                </div>
                                <div className="mt-1 mb-1 text-center">
                                    {errors.quizNotFilled.length === 0 && errors.conditionsSelected.length > 0 && hasErrors &&
                                        <span className='error pt-1 pb-1'>{errors.conditionsSelected}</span>}
                                </div>
                                <div className="d-flex flex-column mb-2 mt-3">
                                    <button className="submit">See Your Results!</button>
                                </div>
                                <p className="is-purple p-2 text-center font-size-18 info-privacy">Your information is 100% confidential and will never be shared with anyone</p>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        );
    }
}

// const encode = (data) => {
//     return Object.keys(data)
//         .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//         .join("&");
// }
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default QuizForm