import React from "react"
import SEO from "../components/seo"
import QuizForm from "../components/QuizForm"


const QuizPage = () => (
  <>
    <SEO title="Nurological quiz" />
    <div className="hero-wrapper quiz" >
        <div style={{ margin: '0 auto', padding: '0rem 1rem'}}>
        < QuizForm />
        </div>
    </div>
  </>
)

export default QuizPage
